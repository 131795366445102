<template>
  <div>
    <v-text-field v-for="(number, index) in parseInt(options.size)"
                  :key="`${questionId}-${number}`"
                  :value="answers[index]"
                  outlined
                  :disabled="isReadSurvey || options.readonly"
                  @input="onUpdateOneValue(index, $event)"
    />
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'SurveyQuestionList',

  mixins: [questionMixins],

  data: () => ({
    answers: [],
  }),

  watch: {
    options: {
      deep: true,
      handler: function () {
        this.updateAnswersArraySize()
      },
    },
  },

  mounted () {
    this.updateAnswersArraySize()
  },

  methods: {
    updateAnswersArraySize () {
      this.answers.splice(0, this.answers.length)

      for (let i = 0; i < this.options.size; i++) {
        this.answers.push(this.value[i] ? this.value[i] : null)
      }
    },
    onUpdateOneValue (index, newValue) {
      this.answers[index] = newValue
      this.$emit('update-value', this.answers)
    },
  },
}
</script>
